import React, {FC} from 'react';
import Layout from '../layouts/Primary';
import SubHeader from '../components/SubHeader';

const FileNotFound: FC = () =>
	<Layout>
		<SubHeader
			title="Page Not Found"
		/>
		<div className="ex-basic-2">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="text-container">
							<h3>404 - Sorry Page Not Found</h3>
						</div>

						<a className="btn-outline-reg"
							href="/">BACK</a>
					</div>
				</div>
			</div>
		</div>
	</Layout>;

export default FileNotFound;
